import request from '@/utils/request'

/**
 * 查询地点申请
 */
export const findCommunityApply = (data) => {
  return request({
    url: '/iCommunityApply/findCommunityApply',
    method: 'POST',
    data
  })
}

/**
 * 添加地点申请
 */
export const addCommunityApply = (data) => {
  return request({
    url: '/iCommunityApply/addCommunityApply',
    method: 'POST',
    data
  })
}

/**
 * 修改地点申请
 */
export const editCommunityApply = (data) => {
  return request({
    url: '/iCommunityApply/editCommunityApply',
    method: 'POST',
    data
  })
}

/**
 * 删除地点申请
 */
export const remCommunityApply = (data) => {
  return request({
    url: '/iCommunityApply/remCommunityApply',
    method: 'POST',
    data
  })
}
