<template>
  <div style="height: 100%">
    <!--【版头】标题 -->
    <nesi-user-common :title="`预约管理`">
      <template #button v-if="form.type==1">
        <el-button type="primary" autofocus="autofocus1" plain @click="setAddTableRowForm(1)">预约房源</el-button>
        <el-button type="primary" autofocus="autofocus2" plain @click="setAddTableRowForm(2)">预约合同</el-button>
      </template>
      <template #content>
        <el-table
        :data="tableData"
        v-if="form.appointType==1"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f5f7fa'
        }">
        <el-table-column prop="house_location" label="房源详情"/>
        <el-table-column label="联系人" width="100">
          <template #default="scope">
            <el-labe v-if="isTenant">{{scope.row.publishName}}</el-labe>
            <el-labe v-else>{{scope.row.appointName}}</el-labe>
          </template>
        </el-table-column>
<!--        <el-table-column prop="publishName" label="联系人" width="100" v-if="form.type===1" />-->
        <el-table-column prop="kfrs" label="看房人数" width="80"/>
        <el-table-column prop="yysj" label="预约时间" width="160"/>
        <el-table-column prop="status" label="预约状态" width="130"/>
        <el-table-column label="操作" width="120">
          <template #default="scope">  <!--  状态标记：-1：预约拒绝；0：已预约；1：预约成功；-->
            <el-button
              type="text"
              v-if="scope.row.statemark == 0 && form.type == 1"
              size="small"
              class="list-btn"
              @click="edit(scope.row)"
            >修改
            </el-button>
            <el-button
              type="text"
              size="small"
              v-show="scope.row.statemark === 0  && form.type == 1"
              class="list-btn"
              style="color: red"
              @click="cancles(scope.row)"
            >取消
            </el-button>
            <el-button
              type="text"
              size="small"
              v-show="scope.row.statemark === 0   && form.type == 2"
              class="list-btn"
              style="color: red"
              @click="agreeAppointActions(scope.row)"
            >同意
            </el-button>
            <el-button
              type="text"
              size="small"
              v-show="scope.row.statemark === 0   && form.type == 2"
              class="list-btn"
              style="color: red"
              @click="dontAgreeAppointActions(scope.row)"
            >拒绝
            </el-button>
            <el-button
              type="text"
              size="small"
              class="list-btn"
              @click="mobile(scope.row)"
            >拨打号码
            </el-button>
            <el-button
              type="text"
              size="small"
              v-show="scope.row.statemark != 0"
              class="list-btn"
              @click="hidened(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
        <!-- 预约合同 -->
        <el-table
          :data="tableData2"
          v-if="form.appointType==2"
          border
          style="width: 100%"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f5f7fa'
        }">
          <el-table-column prop="house_location" label="房源详情"/>
          <el-table-column prop="publishName" label="联系人" width="100"/>
          <el-table-column prop="kfrs" label="租赁部位" width="80"/>
          <el-table-column prop="beginTime" label="开始时间" width="160"/>
          <el-table-column prop="endTime" label="结束时间" width="160"/>
          <el-table-column prop="status" label="预约状态" width="130"/>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button
                type="text"
                v-if="scope.row.statemark === 0"
                size="small"
                class="list-btn"
                @click="edit(scope.row)"
              >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.statemark === 0"
                class="list-btn"
                style="color: red"
                @click="del(scope.row)"
              >取消
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.statemark === 0"
                class="list-btn"
                @click="mobile(scope.row)"
              >拨打号码
              </el-button>
              <el-button
                type="text"
                size="small"
                v-if="scope.row.statemark != 0"
                class="list-btn"
                @click="hidened(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 预约合同 -->
        <el-pagination
          background
          layout="total, prev, pager, next"
          @current-change="currentChange"
          :total="total"
          :page-size="form.pageSize"
          style="text-align: right; margin: 10px;"
        />
      </template>
    </nesi-user-common>
    <!--【弹窗】新增 -->
    <el-dialog title="预约信息提交" width="500px"
               v-model="addTableRowDialogVisible">
      <el-form :inline="true" :model="addTableRowForm" class="demo-form-inline">
        <el-form-item label="合同开始时间" label-width="200px">
          <el-date-picker
            v-model="addTableRowForm.statrTime"
            type="date"
            placeholder="请选择合同开始时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
        <el-form-item label="合同结束时间" label-width="200px">
          <el-date-picker
            v-model="addTableRowForm.endTime"
            type="date"
            placeholder="请选择合同结束时间"
            :disabled-date="disabledEndDate"
          />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addTableRowDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="commintYuYue(2)">提交</el-button>
      </span>
      </template>
    </el-dialog>

    <!--【弹窗】新增 -->
    <el-dialog title="预约信息提交" width="500px"
               v-model="addTableRowDialogVisible2">
      <el-form :inline="true" :model="addTableRowForm" class="demo-form-inline">
        <el-form-item label="看房人数" label-width="200px">
            <el-input v-model="addTableRowForm2.kfrs" style="width: 220px" placeholder="请填写看房人数" />
        </el-form-item>
        <el-form-item label="预约时间" label-width="200px">
          <el-date-picker
            v-model="addTableRowForm2.appointTime"
            type="datetime"
            placeholder="请选择预约时间"
            :disabled-date="disabledDate"
          />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addTableRowDialogVisible2 = false">取消</el-button>
        <el-button type="primary" @click="commintYuYue(1)">提交</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  import { defineComponent, reactive, ref, onMounted, h, computed } from 'vue'
  import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
  import { findCommunityApply, addCommunityApply, editCommunityApply } from '@/api/user-community'
  import { postAppointSave, postAppointList, postAppointCancel, postFreeVirtualPhone, postAppointDel, agreeAppoint } from '@/api/user-house'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  const days = require('dayjs')
  export default {
    components: {
      NesiUserCommon
    },
    props: ['appointment'],
    setup(props) {
      /**
       * 添加
       */
      const addTableRowDialogVisible = ref(false)
      const addTableRowForm = reactive({
        statrTime: '',
        endTime: ''
      })
      const addTableRowDialogVisible2 = ref(false)
      const addTableRowForm2 = reactive({
        kfrs: '',
        appointTime: ''
      })
      let houseId = ''

      const route = useRoute()
      const store = useStore()
      const total = ref(0)
      houseId = route.query.fwid ? route.query.fwid : ''
      const appointType = route.query.appointmentType
      let autofocus1 = route.query.appointmentType != 2
      let autofocus2 = route.query.appointmentType == 2
      addTableRowDialogVisible.value = route.query.appointmentType == 2
      addTableRowDialogVisible2.value = route.query.appointmentType == 1
      const identity = computed(() => {
        if (store.state.user.isTenant) {
          return 1
        } else {
          return 2
        }
      })
      const isTenant = computed(() => {
        return store.state.user.isTenant
      })
      /**
       * 查询
       */
      const form = reactive({
        appointType: 1,
        cardNo: null,
        companyId: null,
        pageNo: 1,
        pageSize: 10,
        type: 0, // 请求者类型1 租客查看预约 2 房东查看预约 3 企业端查看预约看房
        appointId: null
      })

      const tableData = ref([])
      const tableData2 = ref([])

      const postAppointCancelAction = async (cancelAppointmentReq) => {
        const resp = await postAppointCancel(cancelAppointmentReq)
        await findTableList()
      }

      const findTableList = async () => {
        const resp = await postAppointList(form)
        total.value = resp.total

        if (form.appointType == 1) {
          tableData.value = resp.records.map(item => {
            let status = ref('')
            if (item.statemark == -1) {
              status = '预约拒绝'
            } else if (item.statemark == 0) {
              status = '已预约'
            } else if (item.statemark == 1) {
              status = '预约成功'
            } else {
              status = '取消预约'
            }
            return {
              id: item.id,
              agentCompany: item.agentCompany,
              appointName: item.appointName,
              appointUserId: item.appointUserId,
              appointmentType: item.appointmentType,
              beginTime: item.beginTime,
              create_time: item.create_time,
              endTime: item.endTime,
              hidemark: item.hidemark,
              house_location: item.house_location,
              kfrs: item.kfrs,
              modify_time: item.modify_time,
              publicshUserId: item.publicshUserId,
              publishName: item.publishName,
              sfpj: item.sfpj,
              statemark: item.statemark,
              yysj: item.yysj,
              status: status
            }
          })
        } else {
          tableData2.value = resp.records.map(item => {
            let status = ref('')
            if (item.statemark == -1) {
              status = '预约拒绝'
            } else if (item.statemark == 0) {
              status = '已预约'
            } else if (item.statemark == 1) {
              status = '预约成功'
            } else {
              status = '取消预约'
            }
            return {
              id: item.id,
              agentCompany: item.agentCompany,
              appointName: item.appointName,
              appointUserId: item.appointUserId,
              appointmentType: item.appointmentType,
              beginTime: item.beginTime,
              create_time: item.create_time,
              endTime: item.endTime,
              hidemark: item.hidemark,
              house_location: item.house_location,
              kfrs: item.kfrs,
              modify_time: item.modify_time,
              publicshUserId: item.publicshUserId,
              publishName: item.publishName,
              sfpj: item.sfpj,
              statemark: item.statemark,
              yysj: item.yysj,
              status: status
            }
          })
        }
      }

      let statrTime = ref('')
      let endTime = ref('')
      const resetAddTableRowForm = () => {
        statrTime = ''
        endTime = ''
      }
      const commintYuYue = async (index) => {
        let params = {}
        if (index == 1) {
          // 预约看房
          const appointTime = days(addTableRowForm2.appointTime).format('YYYY-MM-DD HH:mm:ss')
          params = {
              type: editItem.value && editItem.value.id ? 2 : 1,
              appointType: appointType || form.appointType,
              peopleCount: addTableRowForm2.kfrs,
              houseId: houseId,
              appointTime: appointTime,
              id: editItem.value && editItem.value.id ? editItem.value.id : null
          }
        } else {
          const start = days(addTableRowForm.statrTime).format('YYYY-MM-DD')
          const end = days(addTableRowForm.endTime).format('YYYY-MM-DD')
          params = {
            type: editItem.value && editItem.value.id ? 2 : 1,
            appointType: appointType || form.appointType,
            beginTime: start,
            houseId: houseId,
            endTime: end,
            id: editItem.value && editItem.value.id ? editItem.value.id : null
          }
        }

        const addResp = await postAppointSave(params)
        addTableRowDialogVisible.value = false
        addTableRowDialogVisible2.value = false
        // addTableRowForm = reactive({
        //   statrTime: '',
        //   endTime: ''
        // })
        // addTableRowForm2 = reactive({
        //   kfrs: '',
        //   appointTime: ''
        // })
        editItem.value = { }
        ElMessage({ type: 'success', message: '提交成功' })
        form.type = 1
        resetAddTableRowForm()
        findTableList()
      }

      const editItem = reactive({})
      const edit = (row) => {
        editItem.value = row
        if (autofocus1) {
          addTableRowDialogVisible2.value = true
          addTableRowForm2.kfrs = editItem.value.kfrs
          addTableRowForm2.appointTime = editItem.value.yysj
        } else {
          addTableRowDialogVisible.value = true
          addTableRowForm.statrTime = editItem.value.beginTime
          addTableRowForm.endTime = editItem.value.endTime
        }

        // addTableRowDialogVisible.value = true
      }

      const cancles = (row) => {
        ElMessageBox.confirm(
          '您确定要取消此预约申请吗?',
          '系统提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        .then(() => {
          postAppointCancelAction({ appointType: row.appointmentType, appointId: row.id })
        })
        .catch(() => {
        })
      }

      const agreeAppointActions = (row) => {
        ElMessageBox.confirm(
          '您确定要同意该预约申请吗?',
          '系统提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        .then(() => {
          postAgreeAppoint({ appointId: row.id, appointType: 1, status: 1 })
        })
        .catch(() => {
        })
      }

      const postAgreeAppoint = async (prams) => {
        const argeeResp = await agreeAppoint(prams)
        addTableRowDialogVisible.value = false
        findTableList()
      }

      const dontAgreeAppointActions = async (row) => {
        ElMessageBox.confirm(
          '您确定要拒绝该预约申请吗?',
          '系统提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        .then(() => {
          postDontAgreeAppoint({ appointId: row.id, appointType: 1, status: 2 })
        })
        .catch(() => {
        })
      }

      const postDontAgreeAppoint = async (prams) => {
        const argeeResp = await agreeAppoint(prams)
        addTableRowDialogVisible.value = false
        findTableList()
      }

      const mobile = async (row) => {
        const addResp = await postFreeVirtualPhone({ mode: 'ax', userId: row.publicshUserId })
        ElMessageBox({
          title: '信息',
          message: h('p', null, [
            h('span', null, '请手机拨打联系人号码： ' + addResp)
          ]),
          showCancelButton: true,
          confirmButtonText: '确认',
          cancelButtonText: '关闭'
          // beforeClose: (action, instance, done) => {
          //   if (action === 'confirm') {
          //     instance.confirmButtonLoading = true
          //     instance.confirmButtonText = 'Loading...'
          //     setTimeout(() => {
          //       done()
          //       setTimeout(() => {
          //         instance.confirmButtonLoading = false
          //       }, 300)
          //     }, 3000)
          //   } else {
          //     done()
          //   }
          // }
        }).then((action) => {
          // ElMessage({
          //   type: 'info',
          //   message: `action: ${action}`
          // })
        })
      }

      const deleteRowrecode = async (delAppointmentReq) => {
        const delResp = await postAppointDel(delAppointmentReq)
        findTableList()
      }

      const hidened = async (row) => {
        ElMessageBox.confirm(
          '请确认是否真的要删除这条记录?',
          '系统提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '再想一想',
            type: 'warning'
          }
        )
        .then(() => {
          deleteRowrecode({
            type: form.type,
            appointType: form.appointType,
            appointId: row.id
          })
        })
        .catch(() => {
        })
      }

      const del = (row) => {
        ElMessageBox.confirm(
          '您确定要取消此预约申请吗?',
          '系统提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        .then(() => {
          postAppointCancelAction({ appointType: row.appointmentType, appointId: row.id })
        })
        .catch(() => {
        })
      }

      const disabledDate = (time) => {
        return time.getTime() < Date.now()
      }

      const disabledEndDate = (time) => {
        const curDate = (new Date()).getTime()
        const nextMonth = 30 * 24 * 3600 * 1000
        const threeMonths = curDate + nextMonth
        return time.getTime() < threeMonths
      }

      const setAddTableRowForm = (index) => {
        tableData.value = []
        tableData2.value = []
        if (index == 1) {
          autofocus1 = true
          autofocus2 = false
          form.appointType = 1
        } else {
          autofocus2 = true
          autofocus1 = false
          form.appointType = 2
        }
        findTableList()
      }

      onMounted(() => {
        form.type = identity.value
        addTableRowForm.statrTime = days()
        addTableRowForm.endTime = days().add(30, 'day')
        addTableRowForm2.endTime = days().add(1, 'day')
        findTableList()
      })
      return {
        form,
        tableData,
        tableData2,
        addTableRowDialogVisible,
        addTableRowForm,
        addTableRowDialogVisible2,
        addTableRowForm2,
        identity,
        isTenant,
        disabledDate,
        cancles,
        disabledEndDate,
        commintYuYue,
        hidened,
        mobile,
        postAgreeAppoint,
        agreeAppointActions,
        dontAgreeAppointActions,
        edit,
        del,
        findTableList,
        setAddTableRowForm
      }
    }
  }

  // defineComponent({
  //   NesiUserCommon
  // })

  // 当前角色
</script>
<style>

  .content {
    margin-top: 5px;
    font-size: 13px;

  p {
    padding: 3px 0;
  }

  }
  .demo-date-picker {
    display: flex;
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
  }
  .demo-date-picker .block {
    padding: 30px 0;
    text-align: center;
    border-right: solid 1px var(--el-border-color);
    flex: 1;
  }
  .demo-date-picker .block:last-child {
    border-right: none;
  }
  .demo-date-picker .demonstration {
    display: block;
    color: var(--el-text-color-secondary);
    font-size: 14px;
    margin-bottom: 20px;
  }
</style>
